import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
  import demo from "./lookPresentation.gif";
  import tv from "./television-2237425_960_720.png";
import training from "./../../training.svg";

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '#ffffff',
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function ProductValues(props) {
  const { classes } = props;
  return (
    <section className={classes.root}>
      <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
      >
        <Grid
            container
            direction="row-reverse"
            justify="center"
            alignItems="center"
        >
          <div style={{maxWidth:500, padding:20}}>
            <img width={70} src={training}/>

            <Typography variant="h4" style={{textTransform: 'none'}} >Show presentation made of web tiles!</Typography>
            <Typography variant="body1" style={{textTransform: 'none'}}>Create and show presentations made of websites to see al the information you and your team needs.
            </Typography>
          </div>
          <div style={{width: 800, position: 'relative', display: 'inline'}}>
            <img src={tv} style={{ width: '100%' }}/>
            <img src={demo} style={{paddingLeft: 0, paddingRight: '2%', paddingTop:4, paddingBottom: 300,marginBottom:20, width: '98%',  position: 'absolute', top: 0, right: 0 }}/>
          </div>

        </Grid>
      </Grid>
    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);
