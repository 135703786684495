import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import Grid from "@material-ui/core/Grid";
import windowsLogo from "./windows-logo-silhouette.svg";
import linux from "./linux.svg";
import apple from "./apple.svg";


import Logo from './dashoblogo.png';
import windows from './windows-logo-silhouette.svg';
import {Analytics} from "../../analyticsTracker";
import {downloadLink} from "../../strings";

const backgroundImage =
  'https://i.imgur.com/rLqF2rd.jpg';

const styles = theme => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#aed9cf', // Average color of the background image.
    backgroundPosition: 'center',
  },
  button: {
      marginTop: 10,
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },

  },
  more: {
    marginTop: theme.spacing(2),
  },
});
function getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
}


function ProductHero(props) {
  const { classes } = props;
    function onDownloadClick() {
        Analytics.track('Download Clicked');
        // if(getOS() === 'Windows') {
        //     window.open('https://firebasestorage.googleapis.com/v0/b/dashob-a81bc.appspot.com/o/Dashob-setup.exe?alt=media&token=fe3a8868-c0ee-4b97-ae22-25161ce12bbf', '_blank');
        // } else if(getOS() === 'Mac OS') {
        //     window.open('https://www.apple.com/', '_blank');
        // } else {
        //     alert('Download available only for Windows or Mac OS')
        // }
        window.open(downloadLink, '_blank');
    }


    return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
        <br/><br/>
        <br/>
        <img src={Logo}   height={100} style={{margin:10}}/>
        <Typography color="inherit" align="center" variant="h2" marked="center" style={{textTransform: 'none'}}>
            Boards & presentations made of
            <br/>
            web tiles with Dashob
        </Typography>

      <Typography style={{margin: 20}} color="inherit" align="center" variant="h5" className={classes.h5}>
          A web browser that allow you to
          <br/>
          create limitless boards with web pages.
      </Typography>

        <br/><br/>


      <Button
        color="secondary"
        variant="contained"
        size="large"
        className={classes.button}
        component="a"
        onClick={    onDownloadClick}
      >
          <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
          >
                <span style={{marginTop:7, marginRight: 10}}>

<img src={windowsLogo} height={18} style={{margin: 5 }} />
{/*<img src={appleLogo} height={20} style={{marginBottom:4}}/>*/}
              </span>
              <span style={{color: '#ffffff'}}>Download for Windows</span>
          </Grid>
      </Button>
        <Button
            color="secondary"
            variant="contained"
            size="large"
            className={classes.button}
            component="a"
            onClick={ () => {
                Analytics.track('Download Mac Clicked');
                window.open('https://firebasestorage.googleapis.com/v0/b/dashob-a81bc.appspot.com/o/Dashob.dmg?alt=media&token=de6eed63-9957-4b84-9cc3-eb8cb81cb8e4', '_blank');
            }}
        >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <span style={{marginTop:7, marginRight: 10}}>

<img src={apple} height={20} style={{margin: 5 }} />
                    {/*<img src={appleLogo} height={20} style={{marginBottom:4}}/>*/}
              </span>
                <span style={{color: '#ffffff'}}>Download for Mac Os</span>
            </Grid>
        </Button>

        <Button
            color="secondary"
            variant="contained"
            size="large"
            className={classes.button}
            component="a"
            onClick={ () => {
                Analytics.track('Download Mac Clicked');
                window.open('https://firebasestorage.googleapis.com/v0/b/dashob-a81bc.appspot.com/o/Dashob-linux-x64.rar?alt=media&token=72657503-dff7-4013-ad54-3ecb33eee97a', '_blank');
            }}
        >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <span style={{marginTop:7, marginRight: 10}}>

<img src={linux} height={25} style={{margin: 5 }} />
                    {/*<img src={appleLogo} height={20} style={{marginBottom:4}}/>*/}
              </span>
                <span style={{color: '#ffffff'}}>Download for Linux</span>
            </Grid>
        </Button>



        {/*<Button color="inherit" align="center"  style={{marginTop:10}} href='/maclinuxupdate'>*/}
        {/*    Coming soon for Mac OS & Linux! Update me when it is ready*/}
        {/*</Button>*/}


    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
