import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga';

const isDev = process.env.NODE_ENV !== 'production';
const MIXPANEL_TOKEN ='93f379ea24453f5c3e2bfe6c2ab53ace';
const GA_CODE = 'UA-101660845-4';

initialise();

const actions = {
  identify: id => {
    if (!isDev) mixpanel.identify(id);
  },
  alias: id => {
    if (!isDev) mixpanel.alias(id);
  },
  track: (name, props) => {
      mixpanel.track(name, props);
      ReactGA.event(props);
  },
  logPageView: () => {
    ReactGA.set({ page: window.location.pathname + window.location.search });
    ReactGA.pageview(window.location.pathname + window.location.search);
    mixpanel.track('Page View', {
      Page: window.location.pathname + window.location.search,
    });
    mixpanel.register({
      SourcePage: window.location.pathname + window.location.search,
    });
  },
  people: {
    set: props => {
      if (isDev) mixpanel.people.set(props);
    },
  },
};

function initialise(){
  mixpanel.init(MIXPANEL_TOKEN);

  mixpanel.register({
    SourcePage: window.location.pathname,
    SourceURLSearch: window.location.search,
  });
//
  ReactGA.initialize(GA_CODE, {
    debug: false,
    titleCase: false,
  });

}
export const Analytics = actions;
