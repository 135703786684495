import React from 'react';
import logo from './logo.svg';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import './App.css';
import Home from './Home'
import EULA from './EULA'
import Terms from './Terms'
import Privacy from './Privacy'
import Contact from './Contact'
import OSUpdate from "./OSUpdate";
import Feedback from "./Feedback";
import DashobHome from "./DashobHome";
import Dasho from "./Dasho";

function App() {
  return (
    <div className="App">
        <BrowserRouter>
        <Switch>
            <Route exact path='/' component={Home}/>
            <Route path='/EULA' component={EULA}/>
            <Route path='/cib46t75gfb75f' component={DashobHome}/>
            <Route path='/home' component={Dasho}/>
            <Route path='/terms' component={Terms}/>\
            <Route path='/privacy' component={Privacy}/>
            <Route path='/contact' component={Contact}/>
            <Route path='/feedback' component={Feedback}/>
            <Route path='/maclinuxupdate' component={OSUpdate}/>
        </Switch>
        </BrowserRouter>
    </div>
  );
}

export default App;
