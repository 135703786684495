import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import AppBar from '../components/AppBar';
import Toolbar, { styles as toolbarStyles } from '../components/Toolbar';
import Logo from './dashoblogo.png';
import Button from "../components/Button";
import windowsLogo from "./windows-logo-silhouette.svg";
import appleLogo from "./apple.svg";
import ProductHeroLayout from "./ProductHeroLayout";
import {Analytics} from "../../analyticsTracker";
import {downloadLink} from "../../strings";
const styles = theme => ({
  title: {
    fontSize: 24,
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: 'space-between',

  },
  left: {
    flex: 1,
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
});


function getOS() {
  var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

function AppAppBar(props) {
  const { classes } = props;
  function onDownloadClick() {
    Analytics.track('Download Clicked');
    // if(getOS() === 'Windows') {
    //     window.open('https://firebasestorage.googleapis.com/v0/b/dashob-a81bc.appspot.com/o/Dashob-setup.exe?alt=media&token=fe3a8868-c0ee-4b97-ae22-25161ce12bbf', '_blank');
    // } else if(getOS() === 'Mac OS') {
    //     window.open('https://www.apple.com/', '_blank');
    // } else {
    //     alert('Download available only for Windows or Mac OS')
    // }
    window.open(downloadLink, '_blank');
  }


  return (
    <div style={{padding:0}}>
      <AppBar style={{     padding:0, position: 'fixed', left:0}}>
        <Toolbar className={classes.toolbar}>
          <div >
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            className={classes.title}
            href="/"
          >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
            >
            <img src={Logo}   height={40} style={{marginRight:10}}/>
              <span style={{textTransform: 'none' }}> Dashob</span>
            </Grid>
          </Link>
          </div>
          <div className={classes.right}>

            {/*<Link*/}
            {/*  color="inherit"*/}
            {/*  variant="h6"*/}
            {/*  underline="none"*/}
            {/*  className={classes.rightLink}*/}
            {/*  href="/premium-themes/onepirate/sign-in/"*/}
            {/*>*/}
            {/*  {'Sign In'}*/}
            {/*</Link>*/}
            {/*<Link*/}
            {/*  variant="h6"*/}
            {/*  underline="nonsee"*/}
            {/*  className={clsx(classes.rightLink, classes.linkSecondary)}*/}
            {/*  href="/premium-themes/onepirate/sign-up/"*/}
            {/*>*/}
            {/*  {'Sign Up'}*/}
            {/*</Link>*/}
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </div>
  );
}

AppAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppAppBar);
