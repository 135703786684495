import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Button} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import feedbackImg from "./views/feedback.png"
const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    padding: 20,
    backgroundColor: '#ffffff',
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function FeedbackArea(props) {
  const { classes } = props;
  return (
    <section className={classes.root}>
      {/* eslint-disable-next-line react/jsx-no-undef */}
      <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
      >
            <Typography variant="h5" style={{textTransform: 'none'}}>Tell us what you think and help us improve Dashob</Typography>
        <img src={feedbackImg} style={{width: 400, maxWidth: '100%', marginTop: 15}}/>
            <Button style={{color:"#E65100"}} href="/feedback">GO To feedback</Button>
      </Grid>
    </section>
  );
}

FeedbackArea.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FeedbackArea);
