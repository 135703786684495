import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Markdown from './modules/components/Markdown';
import Typography from './modules/components/Typography';
import AppAppBar from './modules/views/AppAppBar';
import terms from './modules/views/terms.md';
import AppFooter from './modules/views/AppFooter';

export default class Dasho extends React.Component {

    render() {
        return (
            <React.Fragment>
                <Container>
                        <iframe src='/ad.html' width='100%' />

                </Container>
            </React.Fragment>
        );
    }
}

