import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import demo from "./look.gif";
import Button from '../components/Button';
import ProductHeroLayout from './ProductHeroLayout';
import windowsLogo from "./windows-logo-silhouette.svg";
import apple from "./apple.svg";
import {Analytics} from "../../analyticsTracker";
import {downloadLink} from "../../strings";
import linux from "./linux.svg";

const styles = theme => ({
    button: {
        marginTop: 10
    },
  root: {
    display: 'flex',
    overflow: 'hidden',
      background: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
}


function ProductValues(props) {
  const { classes } = props;

    function onDownloadClick() {
        Analytics.track('Download Clicked');
        // if(getOS() === 'Windows') {
        //     window.open('https://firebasestorage.googleapis.com/v0/b/dashob-a81bc.appspot.com/o/Dashob-setup.exe?alt=media&token=fe3a8868-c0ee-4b97-ae22-25161ce12bbf', '_blank');
        // } else if(getOS() === 'Mac OS') {
        //     window.open('https://www.apple.com/', '_blank');
        // } else {
        //     alert('Download available only for Windows or Mac OS')
        // }
        window.open(downloadLink, '_blank');
    }


  return (
    <section className={classes.root}>
      <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
      >
          <br/>

          <Typography style={{maxWidth:900, textTransform: 'none'}} variant="h4">Get Dashob today for faster overview of all the important web pages you need!</Typography>
          <br/>

        <Button
            color="secondary"
            variant="contained"
            size="large"
            className={classes.button}
            component="a"
            onClick={    onDownloadClick}
            >
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
                <span style={{marginTop:7, marginRight: 10}}>

<img src={windowsLogo} height={18} style={{margin: 5 }} />
                    {/*<img src={appleLogo} height={20} style={{marginBottom:4}}/>*/}
              </span>
            <span style={{color: '#ffffff'}}>Download for Windows</span>
        </Grid>
      </Button>
        <Button
            color="secondary"
            variant="contained"
            size="large"
            className={classes.button}
            component="a"
            onClick={ () => {
                Analytics.track('Download Mac Clicked');
                window.open('https://firebasestorage.googleapis.com/v0/b/dashob-a81bc.appspot.com/o/Dashob.dmg?alt=media&token=de6eed63-9957-4b84-9cc3-eb8cb81cb8e4', '_blank');
            }}
        >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <span style={{marginTop:7, marginRight: 10}}>

<img src={apple} height={20} style={{margin: 5 }} />
                    {/*<img src={appleLogo} height={20} style={{marginBottom:4}}/>*/}
              </span>
                <span style={{color: '#ffffff'}}>Download for Mac Os</span>
            </Grid>
        </Button>

        <Button
            color="secondary"
            variant="contained"
            size="large"
            className={classes.button}
            component="a"
            onClick={ () => {
                Analytics.track('Download Mac Clicked');
                window.open('https://firebasestorage.googleapis.com/v0/b/dashob-a81bc.appspot.com/o/Dashob-linux-x64.rar?alt=media&token=72657503-dff7-4013-ad54-3ecb33eee97a', '_blank');
            }}
        >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <span style={{marginTop:7, marginRight: 10}}>

<img src={linux} height={25} style={{margin: 5 }} />
                    {/*<img src={appleLogo} height={20} style={{marginBottom:4}}/>*/}
              </span>
                <span style={{color: '#ffffff'}}>Download for Linux</span>
            </Grid>
        </Button>

        <br/>
          <br/>
      </Grid>


    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);
