import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import demo from './look.gif';
import dashboard from './../../home.svg';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(9),
    backgroundColor: theme.palette.secondary.light,
  },
  button: {
    border: '4px solid currentColor',
    borderRadius: 0,
    height: 'auto',
    padding: theme.spacing(2, 5),
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buoy: {
    width: 60,
  },
});

function ProductSmokingHero(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
      >
      <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
      >
          <div style={{maxWidth:500, paddingTop: 50}}>
              <img width={70} src={dashboard}/>
          <Typography variant="h4" style={{textTransform: 'none'}}>See an Infinite number of web tiles on a board!
          </Typography>
          <Typography variant="body1" style={{textTransform: 'none'}}>Add and resize an infinite number of web tiles to see the websites you need in front of you.
          </Typography>
          </div>
          <div style={{width: 800}}>
              <img src={demo} style={{padding:20, width: '100%' }}/>
          </div>
      </Grid>
      </Grid>
    </div>
  );
}

ProductSmokingHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductSmokingHero);
