import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import ProductCategories from './modules/views/ProductCategories';
import ProductSmokingHero from './modules/views/ProductSmokingHero';
import AppFooter from './modules/views/AppFooter';
import ProductHero from './modules/views/ProductHero';
import ProductSmokingChrome from './modules/views/ProductSmokingChrome';
import ProductValues from './modules/views/ProductValues';
import ProductHowItWorks from './modules/views/ProductHowItWorks';
import ProductCTA from './modules/views/ProductCTA';
import DownloadRow from './modules/views/DownloadRow';
import AppAppBar from './modules/views/AppAppBar';
import { Analytics } from './analyticsTracker';
import UsageDemo from "./modules/views/UsageDemo";
import FeedbackArea from "./modules/FeedbackArea";

function Index() {
    Analytics.track('Home Page Open');
  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHero />
        <ProductSmokingHero />
        <ProductValues />
        <ProductSmokingChrome />
        <UsageDemo />
        <DownloadRow />
        <FeedbackArea />
        {/*<ProductCategories />*/}
      {/*<ProductHowItWorks />*/}
      {/*<ProductCTA />*/}
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);
