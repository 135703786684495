import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Markdown from './modules/components/Markdown';
import Typography from './modules/components/Typography';
import AppAppBar from './modules/views/AppAppBar';
import privacy from './modules/views/privacy.md';
import AppFooter from './modules/views/AppFooter';

function OSUpdate() {
  return (
    <React.Fragment>
      <AppAppBar />
      <Container>
        <Box mt={7} mb={12}>
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLScDk3dI51D7dmr_V_YmaeiagmhmFAz-ItQjFcGEnAEnyn2brQ/viewform?embedded=true"
                width="100%" height="926" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
            </iframe>
        </Box>
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}
export default withRoot(OSUpdate);