import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import demo from './chromium.png';
import web from './../../web.svg';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.secondary.light,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    border: '4px solid currentColor',
    borderRadius: 0,
    height: 'auto',
    padding: theme.spacing(2, 5),
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buoy: {
    width: 60,
  },
});

function ProductSmokingHero(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
      >
          <Grid
              style={{margingTop:50}}
              container
              direction="row"
              justify="center"
              alignItems="center"
          >
              <div>
                  <img width={70} src={web}/>
                  <Typography variant="h4" style={{textTransform: 'none'}}>Runs with Chromium web engine</Typography>
                  <Typography variant="body1" style={{textTransform: 'none'}}>Uses the Chromium web engine, the same web engine chrome uses.
                      <br/>
                      in order to deliver high-end web experience, you are used to.
                  </Typography>
              </div>
              <img src={demo} style={{margin:20, height: 250}}/>

          </Grid>
      </Grid>
    </div>
  );
}

ProductSmokingHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductSmokingHero);
